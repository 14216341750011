import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NextLink from 'next/link'
import { Menu as BurgerIcon, Cross as CrossIcon } from '../icons/index'
import { PowUkLogoLarge, PowUkLogoMedium } from '../images/index'
import { SideMenu } from './SideMenu'
import { StandardMenu } from './StandardMenu'
import '../../i18n/i18n'
import { MenuItem } from '../../lib/strapi/responseTypes'

export interface MenuBarProps {
  currentPage: string
  menuItems: [MenuItem]
}
export function MenuBar({ currentPage, menuItems }: MenuBarProps) {
  const { t: translate } = useTranslation()
  const [showSideBarState, setShowSideBarState] = useState(false)
  const [delayHandler, setDelayHandler] = React.useState(
    setTimeout(() => {
      /*do nothing*/
    }, 0)
  )

  function openSideBar() {
    setShowSideBarState(true)
    clearTimeout(delayHandler)
  }
  function handleOnBlur() {
    setDelayHandler(
      setTimeout(() => {
        setShowSideBarState(false)
      }, 10)
    )
  }
  return (
    <div className="flex flex-col justify-center w-full">
      <div className="left-0 max-w-max-content-width mx-auto right-0 w-full">
        <a
          className="focus:not-sr-only focus:ring-2 focus:ring-focus font-bold p-4 rounded-sm sr-only text-primary underline"
          href="#main"
        >
          {translate('skipToContent')}
        </a>
      </div>
      <div className="flex justify-center w-full">
        <div className="bg-gray flex justify-center max-w-max-footer-width w-full">
          <nav className="flex flex-row max-w-max-content-width w-full">
            <div className="flex w-full">
              <NextLink href="/">
                <a aria-label={'POW UK logo'} className="m-3 xl:ml-20">
                  <PowUkLogoLarge className="hidden sm:flex" />
                  <PowUkLogoMedium className="flex sm:hidden" />
                </a>
              </NextLink>
            </div>
            <StandardMenu currentPage={currentPage} sectionData={menuItems} />
            <div
              className="flex items-center px-8 lg:hidden"
              onFocus={openSideBar}
              onBlur={handleOnBlur}
            >
              <button
                className="cursor-pointer focus:ring-2 focus:ring-focus h-auto rounded-sm text-2xl text-primary"
                aria-label="Toggle Side Nav Bar"
                onClick={() => setShowSideBarState(!showSideBarState)}
              >
                {showSideBarState ? <CrossIcon /> : <BurgerIcon />}
              </button>
              {showSideBarState && <SideMenu sectionData={menuItems} currentPage={currentPage} />}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
