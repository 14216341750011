import React from 'react'
import {
  PowUkLogoLarge as PowUkLogo,
  PowFooterMountains as MountainsImage,
} from './images/index'
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from './icons/index'
import { Link, LinkVariant } from './Link'
import { useTranslation } from 'react-i18next'

export function Footer() {
  const { t: translate } = useTranslation()

  const sectionClasses =
    'lg:justify-center pl-4 lg:pl-0 flex mb-6 w-full h-auto sm:w-1/2 lg:w-1/3 overflow-x-hidden text-primary'

  return (
    <div className="flex justify-center overflow-hidden">
      <div className="bg-gray max-w-max-footer-width pt-32 relative w-full">
        <MountainsImage className="-ml-1/2-footer-width left-1/2 mb-48 relative sm:mb-0" />
        <div className="absolute bg-primary-20 bottom-0 left-0 max-w-max-content-width mx-auto right-0">
          <div className="flex flex-wrap relative">
            {/* POW Logo */}
            <div className="flex-col hidden pl-4 w-1/3 lg:flex xl:pl-20">
              <PowUkLogo />
            </div>
            {/* Useful Links Section */}
            <div className={sectionClasses}>
              <div>
                <div className="space-y-1 mt-2">
                  <p>
                    <Link
                      variant={LinkVariant.SUBTLE}
                      href="https://protectourwinters.org/pow-international"
                      translationKey="POWInternational"
                    />
                  </p>
                  <p>
                    <Link
                      variant={LinkVariant.SUBTLE}
                      href="/work-or-volunteer-for-POW"
                      translationKey="careersAndVolunteering"
                    />
                  </p>
                  <p>
                    <Link
                      variant={LinkVariant.SUBTLE}
                      href="/merchandise"
                      translationKey="merchShop"
                    />
                  </p>
                  <p>
                    <Link
                      variant={LinkVariant.SUBTLE}
                      href="/sign-up-to-newsletter"
                      translationKey="newsletterSignUp"
                    />
                  </p>
                </div>
              </div>
            </div>

            {/* Connect With Us Section */}
            <div className={sectionClasses}>
              <div>
                <h4 className="mb-2 text-2xl">{translate('connectWithUs')}</h4>
                <div className="mb-1">
                  <p>13/3 Clarendon Crescent,</p>
                  <p>Edinburgh, EH4 1PU</p>
                </div>
                <Link
                  variant={LinkVariant.SUBTLE}
                  href="/contact-us"
                  translationKey="contactUs"
                />

                {/* Social Media Links */}
                <div className="mt-2 space-x-8">
                  <a
                    href="https://facebook.com/protectourwintersuk"
                    aria-label="Facebook Icon"
                    className="text-xl"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://instagram.com/protectourwintersuk"
                    aria-label="Instagram Icon"
                    className="text-xl"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://twitter.com/uk_protect"
                    aria-label="Twitter Icon"
                    className="text-xl"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/protectourwintersuk"
                    aria-label="LinkedIn Icon"
                    className="text-xl"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
              </div>
            </div>
            {/* Smallprint Section */}
            <div className="bottom-8 pb-6 pl-4 pr-12 pt-3 relative lg:bottom-16 lg:pr-6 lg:py-0 xl:pl-20">
              <div>
                <div className="py-3">
                  <Link
                    variant={LinkVariant.SUBTLE}
                    href="/privacy-policy"
                    translationKey="privacyPolicy"
                  />
                  <span className="px-1">|</span>
                  <Link
                    variant={LinkVariant.SUBTLE}
                    href="/privacy-policy"
                    translationKey="cookies"
                  />
                </div>
                <p className="mb-2 text-xs">
                  <span className="whitespace-nowrap">
                    {translate('copyrightNotice')}
                  </span>
                  {' | '}
                  <span className="whitespace-nowrap">
                    {translate('charityNotice')}
                  </span>
                  {' | '}
                  <span className="whitespace-nowrap">
                    {translate('allRightsReserved')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
