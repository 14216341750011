import Link, { LinkProps } from 'next/link'
import cn from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Chevron as ChevronIcon } from '../icons'
import { MENUBAR_DELAY_MS } from '../../lib/constants'
import { NavLink } from './NavLink'
import { MenuItem } from '../../lib/strapi/responseTypes'

export interface StandardMenuProps {
  currentPage: string
  sectionData: MenuItem[]
}
export function StandardMenu({ currentPage, sectionData }: StandardMenuProps) {

  return (
    <div className="flex h-full justify-end pb-2 w-full lg:space-x-4 xl:pr-20">
      {sectionData.map((section, index) => (
        <StandardMenuItem
          currentPage={currentPage}
          subMenuItems={section.pageLinks} 
          href={section.urlOverride != null ? section.urlOverride : "/" + section.page.data.attributes.Slug} 
          key={index}
          {...section}        />
      ))}
    </div>
  )
}

interface StandardMenuItemProps extends LinkProps {
  currentPage: string
  highlight: boolean
  label: string
  subMenuItems: MenuItem[]
}
function StandardMenuItem({
  currentPage,
  highlight,
  subMenuItems,
  label,
  ...props
}: StandardMenuItemProps) {
  const [focussed, setFocussed] = useState(false)
  const [showSubMenuState, setShowSubMenuState] = useState(false)
  const [delayHandler, setDelayHandler] = useState(
    setTimeout(() => {
      /*do nothing*/
    }, 0)
  )
  const { t: translate } = useTranslation()
  function showSubMenu() {
    clearTimeout(delayHandler)
    setShowSubMenuState(true)
  }
  function hideSubMenu() {
    setShowSubMenuState(false)
  }
  function toggleSubMenu() {
    setShowSubMenuState(!showSubMenuState)
  }
  function pauseAndHideSubMenu() {
    setDelayHandler(
      setTimeout(() => {
        hideSubMenu()
      }, MENUBAR_DELAY_MS)
    )
  }
  const hasSubMenu = subMenuItems.length > 0
  const removeSlash = currentPage.replace("/", "")
  const selected = removeSlash == props.href
  return (
    <div
      className={cn(
        'flex relative md:pr-4 xl:pr-0',
        highlight ? 'hidden md:flex' : 'hidden lg:flex'
      )}
      onMouseEnter={showSubMenu}
      onMouseLeave={hideSubMenu}
      onBlur={pauseAndHideSubMenu}
    >
      <Link {...props}>
        <a
          className={cn(
            'h-full relative focus:outline-none flex items-center py-8 px-2 hover:text-primary-text',
            'cursor-pointer hover:underline whitespace-nowrap rounded-sm ',
            { 'text-primary-text hover:no-underline': selected },
            highlight ? 'bg-quaternary' : 'bg-gray'
          )}
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
        >
          <span
            className={cn(
              'rounded-sm tracking-wider text-primary uppercase font-bold',
              { 'ring-2 ring-focus': focussed }
            )}
          >
            {label}
            {/* Custom underline to indicate selected */}
            {selected && (
              <div className={cn('bg-primary h-2px relative  w-full')} />
            )}
          </span>
        </a>
      </Link>
      {hasSubMenu && (
        <div className="flex items-center z-10">
          <button
            className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-focus font-bold inline-block rounded-sm text-primary"
            onClick={toggleSubMenu}
            tabIndex={0}
            aria-label={'toggle drop down menu'}
          >
            <ChevronIcon
              className={cn('transition-transform', {
                'transform rotate-180': showSubMenuState,
              })}
            />
          </button>
        </div>
      )}
      {showSubMenuState && hasSubMenu && (
        <div
          className="-left-12 absolute bg-white mt-24 py-2 shadow-md top-0 z-30 xl:-left-6"
          onFocus={showSubMenu}
        >
          {subMenuItems.map((item, index) => (
            <NavLink 
            href={ item.urlOverride != null ? item.urlOverride : item.page.data.attributes.Slug} 
            titleTranslationKey={item.label} key={index} {...item} />
          ))}
        </div>
      )}
    </div>
  )
}
