import React from 'react'
import cn from 'classnames'

export enum Variant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
}

export enum Style {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
}

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  variant: Variant
  cssStyle: Style
  headingText: string
  id?: string
}

export function Heading({
  variant = Variant.h1,
  cssStyle = Style.h1,
  headingText = '',
  ...props
}: HeadingProps) {
  let style
  switch (cssStyle) {
    case Style.h1:
      style =
        'text-4xl tracking-2 uppercase sm:text-5xl sm:tracking-3 md:text-6xl md:tracking-4'
      break
    case Style.h2:
      style =
        'text-3xl tracking-1 uppercase sm:text-3xl sm:tracking-2 md:text-5xl md:tracking-3'
      break
    case Style.h3:
      style = 'text-3xl tracking-2 md:text-4xl md:tracking-2'
      break
    case Style.h4:
      style = 'text-2xl tracking-2 md:text-3xl md:tracking-2'
      break
  }

  let component
  switch (variant) {
    case Variant.h1:
      component = (
        <h1 id={props.id} className={cn(style, props.className)}>
          {headingText}
        </h1>
      )
      break
    case Variant.h2:
      component = (
        <h2 id={props.id} className={cn(style, props.className)}>
          {headingText}
        </h2>
      )
      break
    case Variant.h3:
      component = <h3 id={props.id} className={cn(style, props.className)}>{headingText}</h3>
      break
    case Variant.h4:
      component = <h4 id={props.id} className={cn(style, props.className)}>{headingText}</h4>
      break
  }
  return component
}
