import { localize } from 'pseudo-localization'

interface keyValue {
  [key: string]: any
}

// define localization strings
const resources: keyValue = {
  en: {
    translation: {
      'sb-buttonSample': 'BUTTON',
      'sb-linkSample': 'Link in paragraph',
      'sb-subtleLinkSample': 'Subtle link',
      'sb-openNewTabLinkSample': 'open a new tab link',
      'sb-pageHeadingSample': 'Page Heading',
      viewThePledge: 'View the pledge',
      makeThePledge: 'Make the pledge',
      startPro: 'Start Pro',
      startStandard: 'Start Standard',
      startFree: 'Start Free',
      protectOurWintersUk: 'Protect Our Winters UK',
      protectOurWinters: 'Protect Our Winters',
      about: 'About',
      usefulLinks: 'Useful Links',
      POWInternational: 'POW International',
      merchShop: 'Shop POW Merchandise',
      merchShopShort: 'Shop POW Merch',
      newsletterSignUp: 'Sign up to Newsletter',
      connectWithUs: 'Connect With Us',
      contactUs: 'Contact Us',
      privacyPolicy: 'Privacy Policy',
      cookies: 'Cookies',
      accessibility: 'Accessibility',
      copyrightNotice: 'Copyright 2021 Protect Our Winters UK',
      charityNotice: 'Registered Charity SC047595',
      allRightsReserved: 'All Rights Reserved',
      forBusinesses: 'For Businesses',
      forIndividuals: 'For Individuals',
      forCommunityGroups: 'For Community Groups',
      workOrVolunteerForPOW: 'Work or Volunteer for POW',
      openPositions: 'Open Positions',
      seeOpenRoles: 'See open roles',
      chairPerson: 'Chairperson',
      partnershipsManager: 'Partnerships Manager',
      volunteerForPOWUK: 'Volunteer for POW UK',
      accountsManager: 'Accounts Manager',
      automatedTestEngineer: 'Automated Test Engineer',
      communityRep: 'Community Rep',
      frontendEngineer: 'Frontend Engineer',
      PHPDeveloper: 'PHP Developer',
      fullStackEngineer: 'Full Stack Engineer',
      QATestLead: 'QA Test Lead',
      signUpForNewsletter: 'Sign up for our Newsletter',
      aboutUs: 'About Us',
      takeAction: 'Take Action',
      latest: 'Latest',
      donate: 'Donate',
      skipToContent: 'Skip to content',
      aboutPowUk: 'About POW UK',
      ourWork: 'Our Work',
      ourPartners: 'Our Partners',
      businesses: 'Businesses',
      individuals: 'Take Action As An Individual',
      communityGroups: 'Community Groups',
      blog: 'POW UK Blog',
      newsletterSignUpMenu: 'Newsletter Sign Up',
      buyMerchandise: 'Buy Merchandise',
      newsletter: 'Newsletter',
      submit: 'Submit',
      sendLoginLink: 'Send Login Link',
      firstName: 'First name',
      lastName: 'Last name',
      emailAddress: 'Email address',
      phoneNumber: 'Phone number',
      enquiryReason: 'Enquiry reason',
      message: 'Message',
      password: 'Password',
      firstNameRequiredField: 'First name is required',
      lastNameRequiredField: 'Last name is required',
      emailRequiredField: 'An email address is required',
      emailInvalidField: 'Please check your email address',
      passwordRequiredField: 'A password is required',
      passwordCharactersNumError: "Password mustn't be less that 6 characters", //NOSONAR This is a must text but sonar flags that it's a pontetially security issue
      messageRequiredField: 'A message is required',
      regionRequiredField: 'Please select a region',
      forgetEmailInfoText:
        ' In the event you forgot the email used for your account, please email us at pledge@protectourwinters.uk',
      captchaRequiredField: 'Please complete the hCaptcha',
      general: 'General enquiry / Other',
      campaignsFundraising: 'Campaigns / Fundraising',
      donations: 'Donations',
      merchandise: 'Merchandise',
      partnerships: 'Partnerships',
      pledge: 'POW Pledge',
      pressMedia: 'Press / Media',
      universityClub: 'University / Club',
      vacanciesVolunteering: 'Vacancies / Volunteering',
      thanksForMessage: 'Thanks for your message!',
      errorSubmittingMessage:
        'If the issue persists, please contact us via info@protectourwinters.uk',
      seeAllCampaigns: 'See All Campaigns',
      carbonLiteracyProject: 'Carbon Literacy Project',
      carbonLiteracyTraining: 'Carbon Literacy Training',
      brandAlliance: 'Brand Alliance',
      takeThePledge: 'Take The Pledge',
      learnMore: 'Learn More',
      findOutMore: 'Find out more',
      findOutCorporate:
        'Find out how you can become a corporate partner or make a corporate donation.',
      '2020ImpactReport': '2020 Impact Report',
      impactReports: 'Impact Reports',
      getInTouch: 'get in touch',
      fundraise: 'Fundraise',
      volunteer: 'Volunteer',
      howWeUseDonation: 'How we use your donation',
      missionAndValues: 'Mission & Values',
      divestTheDirt: 'Divest The Dirt',
      ourNetwork: 'Our Network',
      ourMission: 'our mission',
      howToBecomeAPOWPartner: 'find out how you can become a POW partner',
      surfdome: 'Surfdome',
      buyNow: 'Buy Now',
      outOfStock: 'Out Of Stock',
      supportPOWUK: 'Support POW UK',
      supportUs: 'Support Us',
      onePercentForPlanet: '1% for planet',
      taxRelief: 'Tax relief',
      impactReport: 'Latest Impact Report',
      beInTouch: 'Get in touch',
      region: 'Region',
      selectRegion: 'Select Region',
      signUp: 'Sign Up',
      scotland: 'Scotland',
      england: 'England',
      wales: 'Wales',
      euMainland: 'EU Mainland',
      other: 'Other',
      northernIreland: 'Northern Ireland',
      otherRegionRequiredField: 'Please specify other region',
      thanksForNewsletterSignUp: 'Thanks for signing up to our newsletter!',
      turnPassionIntoAction: 'Turn Passion Into Action',
      athletesAmbassadors: 'Athletes & Ambassadors',
      divestThePension: 'DIVEST THE PENSION',
      signThePetition: 'Sign the Petition',
      downloadCampaignerPack: 'Download Campaigner Pack',
      privacyStatement: 'Privacy Statement',
      all: 'All',
      none: 'None',
      ambassadors: 'Ambassadors',
      takeActionBusiness: 'Take Action As A Business',
      becomePowPartner: 'Become A POW Partner',
      onePercentForThePlanet: '1% for the Planet',
      signUpToNewsletter: 'sign up to our newsletter now',
      readBlogArticle: 'Read this article',
      shopMerch: 'Shop Merch',
      viaWebform: 'Via Webform',
      useContactPage: 'using our Contact Us page',
      readMoreAboutImpact: 'Read more about our work and impact',
      downloadReport: 'Download Report',
      readMore: 'See current partners',
      goDownOnThePage: 'further down on this page',
      checkWhatWeOffer: 'Check out what else we offer',
      annualReports: 'Annual Reports',
      latestReports: 'Latest Reports',
      latestImpactReport: 'Latest Impact Report',
      contactUsToJoin: 'Contact us to join',
      shopMerchandise: 'Shop merchandise',
      shopPowMerchandise: 'Shop POW Merchandise',
      seeCampaigns: 'See Campaigns',
      askCouncilToDivest: 'ask your local council to #DivestTheDirt',
      here: 'here',
      findOutHowMuchYourLocalAuthorityInvests:
        'Find out how much your local authority invests in fossil fuels here.',
      sendThemAnEmail: 'send them an email',
      divestYourPension: 'Divest Your Pension',
      startConversation: 'Start a Conversation',
      openArticle: ' Open article',
      openPage: 'Open page',
      onlineSpeech:
        'speech at an online hearing of US Congressional committee on fossil fuel subsidies',
      parisClimateAgreement: 'Paris Climate Agreement',
      fossilFuelBurning: 'fossil fuel burning',
      lobbyingGovernements: 'lobbying governments to prevent climate action',
      hotestMonthRecorded: 'July 2021 was the hottest month ever recorded',
      billionsOfPounds: 'billions of pounds',
      oilAndGasInvestment:
        '£1.75 billion from their oil and gas investments between 2017 and 2020',
      fromFossilFuelCompanies: '$14.58 trillion from fossil fuel companies',
      newYorkStatePension:
        'the New York State pension fund, the Welsh Parliament, 89 UK Universities',
      ireland: 'Ireland',
      friendsOfTheEarth: 'Friends of the Earth',
      friendsOfTheEarthScotland: 'Friends of the Earth Scotland',
      walthamForestSouthwarkAndCardiff: 'Waltham Forest, Southwark and Cardiff',
      powDivestTheDirt: "Protect Our Winters' Divest The Dirt",
      friendsOfTheEarthScotlandFossilFuelDivestment:
        'Friends of the Earth Scotland Fossil Fuel Divestment',
      divestUK: 'Divest UK',
      friendsOfTheEarthUKDivestement: 'Friends of the Earth UK Divestment',
      universityToDivest: 'university to divest',
      divestParliamentCampaign: 'Divest Parliament campaign',
      mostPopular: ' Most popular', 
      // Pledges
      FAQspage: ' FAQs page',
      fieldRequiredField: 'Please, fill out this field!',
      setTargetForYourBussiness:
        'Set a target for how your business will work towards achieving Pledge One.',
      saveProgress: 'Save Progress',
      saveAndReview: 'Save and Review',
      HowWillClimateBreakdownAffectYourBussiness:
        'How will climate breakdown affect your business?',
      WhatPlansAreYourOrganisationMaking:
        'What plans are your organisation making to adapt to (be ready for) the affects a changing climate will have?',
      feedbackMessageRequiredField: 'A message is required',
      feedback: 'Feedback',
      date: 'Date',
      details: 'Details',
      amount: 'Amount',
      download: 'Download',
      logo: 'Logo',
      myAccount: 'My Account',
      pledgeMembership: 'Pledge Membership',
      billing: 'Billing',
      notifications: 'Notifications',
      progressTowardsYourPledgeTargets:
        'If you have made some significant progress towards your pledge targets, tell us about it below to progress towards the summit and unlock further marketing assets.',
      reportYourProgress: 'Report Your Progress',
      careersAndVolunteering: "Careers and Volunteering",
    },
  },
  // readable pseudo localizations will be inserted here
  pseudo: {
    translation: {},
  },
  pseudoRTL: {
    translation: {},
  },
}

// attach localized versions of english translations to the pseudo key in resources
function addPseudoForEnglishTranslations(resourcesIn: keyValue) {
  return Object.keys(resourcesIn.en.translation).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: localize(resourcesIn.en.translation[curr]),
    }
  }, {})
}

function addPseudoRTLForEnglishTranslations(resourcesIn: keyValue) {
  return Object.keys(resourcesIn.en.translation).reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: localize(resourcesIn.en.translation[curr], { strategy: 'bidi' }),
    }
  }, {})
}

resources.pseudo.translation = addPseudoForEnglishTranslations(resources)
resources.pseudoRTL.translation = addPseudoRTLForEnglishTranslations(resources)

export default resources
