import { MenuItem } from '../../lib/strapi/responseTypes'
import { NavLink } from './NavLink'
import cn from 'classnames'
export interface StandardMenuProps {
  currentPage: string
  sectionData: MenuItem[]
}
export function SideMenu({ currentPage, sectionData }: StandardMenuProps) {
  return (
    <div className="absolute right-0 shadow-lg top-20 z-20 sm:top-24">
      {sectionData.map((section, index) => (
        <SideMenuItem
          currentPage={section.label} 
          href={section.urlOverride != null ? section.urlOverride : "/" + section.page.data.attributes.Slug} 
          subMenuItems={section.pageLinks} key={index}
          separator={index > 0 && !section.highlight}
          {...section}        />
      ))}
    </div>
  )
}

interface SideMenuItemProps {
  currentPage: string
  href: string
  highlight: boolean
  separator: boolean
  subMenuItems: MenuItem[]
}
function SideMenuItem({
  currentPage,
  separator,
  subMenuItems,
  highlight,
  ...props
}: SideMenuItemProps) {
  return (
    <div className="bg-gray right-0 top-0">
      {separator && (
        <div className="flex items-center justify-center w-full">
          <div className="border-b-2 border-secondary-40 w-4/5 lg:border-b-0" />
        </div>
      )}
      <NavLink titleTranslationKey={currentPage} className={cn(highlight && 'bg-quaternary')} {...props} />
      {subMenuItems.map((item, index) => (
        <NavLink titleTranslationKey={item.label} 
        href={ item.urlOverride != null ? item.urlOverride : item.page.data.attributes.Slug} 
        key={index} className="ml-8" {...item} />
      ))}
    </div>
  )
}
