import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Variant, Style } from './Heading'

interface ContentProps {
  headingTranslationKey?: string
  children: ReactNode
}
export function Content({ headingTranslationKey, children }: ContentProps) {
  const { t: translate } = useTranslation()
  return (
    <div className="flex justify-center">
      <div className="bg-gray flex justify-center max-w-max-footer-width w-full">
        <div
          className='max-w-max-content-width mx-0 pt-8 px-4 space-y-8 top-0 w-full xl:px-20'
        >
          {headingTranslationKey && (
            <Heading
              variant={Variant.h1}
              cssStyle={Style.h1}
              headingText={translate(headingTranslationKey)}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
