import Link, { LinkProps } from 'next/link'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

export interface NavLinkProps extends LinkProps {
  href: string
  titleTranslationKey: string
  className?: string
}
export function NavLink({
  titleTranslationKey,
  href,
  className,
}: NavLinkProps) {
  const [focussed, setFocussed] = useState(false)
  const { t: translate } = useTranslation()

  return (
    <Link href={href}>
      <a
        className={cn(
          'block font-bold hover:underline outline-none px-8 py-6 rounded-sm text-primary tracking-wider uppercase whitespace-nowrap',
          className
        )}
        onFocus={() => setFocussed(true)}
        onBlur={() => setFocussed(false)}
      >
        <span
          className={cn(
            'rounded-sm tracking-wider text-primary uppercase font-bold',
            { 'ring-2 ring-focus': focussed }
          )}
        >
          {translate(titleTranslationKey)}
        </span>
      </a>
    </Link>
  )
}
